import React, { useEffect, useState, useRef } from 'react';
import "./Navbar.css";
import { Link, animateScroll as scroll } from 'react-scroll';
import { FaArrowUpRightFromSquare, FaBarsStaggered, FaInstagram } from "react-icons/fa6";
import { FaTimes } from 'react-icons/fa';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import logo from '../../images/logo.png';

const Navbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [visible, setVisible] = useState(true); // Navbar initially visible
  const [lastScrollY, setLastScrollY] = useState(window.scrollY); // Track the last scroll position

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY && currentScrollY > 100) {
      // If scrolling down past 100px, hide the navbar
      setVisible(false);
    } else if (currentScrollY < lastScrollY) {
      // If scrolling up, show the navbar
      setVisible(true);
    }

    setLastScrollY(currentScrollY); // Update last scroll position
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const container = useRef(null);
  gsap.registerPlugin(useGSAP);

  useEffect(() => {
    if (visible) {
      gsap.fromTo(
        ".navbar__container",
        { y: -250, width: '100%' },
        { y: 0, top: 0, zIndex: 100 }
      );
    }
  }, [visible]);

  useGSAP(() => {
    const timeline = gsap.timeline();
    timeline.from(".tab__item", { opacity: 0, stagger: .5 });
  }, { scope: container });

  return (
    <nav className={`navbar__container ${visible ? "visible" : "hidden"}`} ref={container}>
      {showSidebar ? (
        <div className='overlay' onClick={() => setShowSidebar(!showSidebar)}></div>
      ) : ""}
      <div className="logo__container" onClick={() => scroll.scrollToTop({ duration: 500 })}>
        <img src={logo} alt="Logo" className="logo" />
      </div>

      <div className={`tab__group ${showSidebar ? 'show' : ''}`}>
        <span className="icon__container close__btn" onClick={() => setShowSidebar(!showSidebar)}>
          <FaTimes />
        </span>

        <Link
          activeClass='active'
          className='tab__item'
          to='header'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Accueil
        </Link>

        <Link
          activeClass='active'
          className='tab__item'
          to='about'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          À propos
        </Link>

        <Link
          activeClass='active'
          className='tab__item'
          to='services'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Services
        </Link>

        <Link
          activeClass='active'
          className='tab__item'
          to='contact'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Rejoindre
        </Link>
      </div>

      <div className="nav__buttons__group">
        <a
          href="https://www.instagram.com/yencommunaute?utm_source=ig_web_button_share_sheet&igshid=ZDNlZDc0MzIxNw=="
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn__primary"
        >
          <FaInstagram />
        </a>

        <FaBarsStaggered className="menu" onClick={() => setShowSidebar(!showSidebar)} />
      </div>
    </nav>
  );
};

export default Navbar;
