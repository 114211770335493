import React, { useState } from 'react';
import "./Contact.css";
import { MdOutlineEmail } from 'react-icons/md';
import { FaInstagram } from 'react-icons/fa';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = formData;
    const mailtoLink = `mailto:contact@yencommunity.ca?subject=Message from ${name}&body=Nom: ${name}%0DEmail: ${email}%0D%0DMessage: ${message}`;
    window.location.href = mailtoLink;
  };

  return (
    <section id='contact'>
      <div className="section__wrapper contact__container">
        <div className="section__header">
          <h2 className="primary__title">Rejoignez notre communauté dès maintenant</h2>
          <p className="text__muted description">
          Plongez au cœur d'une communauté dynamique de jeunes entrepreneurs passionnés par l'innovation, le réseautage et le développement personnel et professionnel. Remplissez le formulaire pour rejoindre. En nous rejoignant, vous accéderez à des ressources exclusives, des événements enrichissants et des collaborations inspirantes qui vous aideront à atteindre vos objectifs. N'attendez plus pour transformer vos idées en réalité et faire partie d'un réseau qui propulse votre réussite.
          </p>
        </div>
        <div className="contact__group">
          <div className="contact__options">
            <article className="contact__option">
              <MdOutlineEmail className='contact__icon' />
              <h3>Email</h3>
              <h5>contact@yencommunity.ca</h5>
              <a href="mailto:contact@yencommunity.ca" className='btn'>Envoyer un message</a>
            </article>
            <article className="contact__option">
              <FaInstagram className='contact__icon' />
              <h3>Instagram</h3>
              <h5>YEN</h5>
              <a href="https://www.instagram.com/yencommunaute?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target='_blank' rel='noopener noreferrer' className='btn'>
                Send a message
              </a>
            </article>
          </div>

          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder='Nom complet'
              required
              value={formData.name}
              onChange={handleInputChange}
            />
            <input
              type="email"
              name="email"
              placeholder='Email'
              required
              value={formData.email}
              onChange={handleInputChange}
            />
            <textarea
              name='message'
              rows={7}
              placeholder='Message'
              required
              value={formData.message}
              onChange={handleInputChange}
            ></textarea>
            <button type='submit' className="btn btn__primary">Rejoindre la communauté</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
